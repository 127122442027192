// ------------------------------------
// Imports
// ------------------------------------
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { getOrganisations } from "../assets/common/common";
import apiClient from '../assets/common/interceptor';
// ------------------------------------
// Constants
// ------------------------------------
export const RETRIEVE_OPEN_ORDERS = 'RETRIEVE_OPEN_ORDERS';
export const RETRIEVE_DELIVERED_ORDERS = 'RETRIEVE_DELIVERED_ORDERS';
export const RETRIEVE_ENDED_ORDERS = 'RETRIEVE_ENDED_ORDERS';
export const RETRIEVE_ORDER = 'RETRIEVE_ORDER';
export const RETRIEVE_ORDERS_TO_SIGN = 'RETRIEVE_ORDERS_TO_SIGN';
export const UPDATE_OPEN_ORDERS_FILTERS = 'UPDATE_OPEN_ORDERS_FILTERS';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_CHECKED = 'UPDATE_CHECKED';
export const UPDATE_DELIVERED_ORDERS_FILTERS = 'UPDATE_DELIVERED_ORDERS_FILTERS';
export const UPDATE_ENDED_ORDERS_FILTERS = 'UPDATE_ENDED_ORDERS_FILTERS';
export const UPDATE_SIGNED_ORDERS_FILTERS = 'UPDATE_SIGNED_ORDERS_FILTERS';

export function updateOpenOrderFilters (key, value) {
  return ({
    type: UPDATE_OPEN_ORDERS_FILTERS,
    key,
    value
  });
}

export function updateDeliveredOrderFilters (key, value) {
  return ({
    type: UPDATE_DELIVERED_ORDERS_FILTERS,
    key,
    value
  });
}

export function updateEndedOrderFilters (key, value) {
  return ({
    type: UPDATE_ENDED_ORDERS_FILTERS,
    key,
    value
  });
}

export function updateSignedOrderFilters (key, value) {
  return ({
    type: UPDATE_SIGNED_ORDERS_FILTERS,
    key,
    value
  });
}

export function actionGetOpenOrders (payload) {
  return ({
    type: RETRIEVE_OPEN_ORDERS,
    payload
  });
}

export function actionUpdateChecked (payload) {
  return ({
    type: UPDATE_CHECKED,
    payload
  });
}

export function actionGetDeliveredOrders (payload) {
  return ({
    type: RETRIEVE_DELIVERED_ORDERS,
    payload
  });
}

export function actionGetEndedOrders (payload) {
  return ({
    type: RETRIEVE_ENDED_ORDERS,
    payload
  });
}

export function actionGetOrder (payload) {
  return ({
    type: RETRIEVE_ORDER,
    payload
  });
}

export function updateLoadingValue (payload) {
  return ({
    type: UPDATE_LOADING,
    payload
  });
}

export function updateErrorMessageValue (payload) {
  return ({
    type: UPDATE_ERROR_MESSAGE,
    payload
  });
}

export function clearFiltersAction () {
  return ({
    type: CLEAR_FILTERS
  });
}

export const updateOpenOrderFilterValue = (key, value) => {
  return (dispatch) => {
    dispatch(updateOpenOrderFilters(key, value));
  };
};

export const updateEndedOrderFilterValue = (key, value) => {
  return (dispatch) => {
    dispatch(updateEndedOrderFilters(key, value));
  };
};

export const updateDeliveredOrderFilterValue = (key, value) => {
  return (dispatch) => {
    dispatch(updateDeliveredOrderFilters(key, value));
  };
};

export const updateSignedOrderFilterValue = (key, value) => {
  return (dispatch) => {
    dispatch(updateSignedOrderFilters(key, value));
  };
};

export const clearFilters = () => {
  return (dispatch) => {
    dispatch(clearFiltersAction());
  };
};

export function fetchOrdersToSign (payload) {
  return ({
    type: RETRIEVE_ORDERS_TO_SIGN,
    payload
  });
}

export const setChecked = (data) => {
  return (dispatch) => {
    dispatch(actionUpdateChecked(data));
  };
};

const getQueryParams = (sortBy, direction, filterString, searchInput) => {
  let queryParams = `?sortBy=${sortBy}&orderBy=${direction}`;
  if (filterString) {
    queryParams += `${filterString}`;
  }
  if (searchInput) {
    queryParams += `&${searchInput}`;
  }

  return queryParams;
};

// ------------------------------------
// API calls
// ------------------------------------
// ------------------------
// GET
// ------------------------
const fetchOrders = (orderType, sortBy = "", direction = "", filters = {}, signal, searchInput) => {
  return (dispatch) => {
    dispatch(updateLoadingValue(true));

    const orgId = filters.organisation;
    let filterString = "";
    Object.keys(filters).forEach((key) => {
      if (typeof filters[key] === 'undefined') {
        filters[key] = 'All';
      }
      if (key !== "organisation" && filters[key] !== "All") {
        if (Array.isArray(filters[key])) {
          filters[key].map(e => {
            filterString += `&${key}[]=${e}`;
          });
        } else {
          filterString += `&${key}=${filters[key]}`;
        }
      }
    });

    const queryParams = getQueryParams(sortBy, direction, filterString, searchInput);

    return apiClient.get(`/organisation/orders/${orderType}/${orgId}${queryParams}`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        if (data && typeof data === 'object' && data.status !== false) {
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty('message')
              && data.message === `No ${orderType.replace(/^\w/, c => c.toUpperCase())} orders present`) {
            dispatch(updateErrorMessageValue(data.message));
            dispatch(updateLoadingValue(false));
          } else {
            dispatch(updateErrorMessageValue(""));
            switch (orderType) {
            case 'open':
              dispatch(actionGetOpenOrders(data));
              break;
            case 'delivered':
              dispatch(actionGetDeliveredOrders(data));
              break;
            case 'ended':
              dispatch(actionGetEndedOrders(data));
              break;
            case 'signed':
              dispatch(fetchOrdersToSign(data));
              break;
            default:
              break;
            }
            dispatch(updateLoadingValue(false));
          }
          return Promise.resolve(data);
        } else {
          console.error('Nothing found');
          // If there was a problem...
          return Promise.reject(data);
        }
      })
      .catch(err => {
        if (!signal?.aborted) {
          localStorage.setItem('errorMessage', err.message);
          window.location.href = "/";
          console.error("No orders");
          console.error(err);
        }
      });
  };
};

export const getOpenOrders = (sortBy, direction, filters, signal, searchInput) => {
  return fetchOrders('open', sortBy, direction, filters, signal, searchInput);
};

export const getDeliveredOrders = (sortBy, direction, filters, signal, searchInput) => {
  return fetchOrders('delivered', sortBy, direction, filters, signal, searchInput);
};

export const getEndedOrders = (sortBy, direction, filters, signal, searchInput) => {
  return fetchOrders('ended', sortBy, direction, filters, signal, searchInput);
};

export const getOrdersToSign = (sortBy, direction, filters, signal, searchInput) => {
  return fetchOrders('signed', sortBy, direction, filters, signal, searchInput);
};

// ------------------------
// POST
// ------------------------
export const sendSignedOrders = (data) => {
  return (dispatch) => {
    dispatch(updateLoadingValue(true));

    return apiClient.post('/sign', data)
      .then(response => {
        return response.data;
      })
      .then(data => {
        if (data && typeof data === 'object' && data.status !== false) {
          dispatch(updateLoadingValue(false));
          return Promise.resolve(data);
        } else {
          // If there was a problem...
          dispatch(updateLoadingValue(false));
          return Promise.reject(data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        console.error(err);
      });
  };
};

export const getOrder = (id) => {
  return (dispatch) => {
    return apiClient.get(`/organisation/order/${id}`)
      .then(response => {
        return response?.data;
      })
      .then(data => {
        if (data && typeof data === 'object' && data.status !== false) {
          dispatch(actionGetOrder(data));
          return Promise.resolve(data);
        } else {
          // If there was a problem...
          return Promise.reject(data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        console.error(err);
      });
  };
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  openOrders: [],
  deliveredOrders: [],
  endedOrders: [],
  checked: [],
  order: [],
  isLoading: true,
  errorMessage: "",
  openOrdersFilterValues: {
    "orderType": "All",
    "locationName": "All",
    "actionType": "All",
    "locationService": "All",
    "executionDate": "All",
    "status": "All"
  },
  endedOrdersFilterValues: {
    "orderType": "All",
    "locationName": "All",
    "actionType": "All",
    "locationService": "All",
    "executionDate": "All",
    "status": "All"
  },
  deliveredOrdersFilterValues: {
    "orderType": "All",
    "locationName": "All",
    "actionType": "All",
    "locationService": "All",
    "executionDate": "All",
    "status": "All"
  },
  signedOrdersFilterValues: {
    "orderType": "All",
    "locationName": "All",
    "actionType": "All",
    "locationService": "All",
    "executionDate": "All",
    "status": "All",
  },
  ordersToSign: [],
  pagination: {},
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
  case UPDATE_OPEN_ORDERS_FILTERS:
    return {
      ...state, openOrdersFilterValues: {
        ...state.openOrdersFilterValues, [action.key]:
                    action.value, page: 1
      }
    };
  case UPDATE_DELIVERED_ORDERS_FILTERS:
    return {
      ...state, deliveredOrdersFilterValues:
                    { ...state.deliveredOrdersFilterValues, [action.key]: action.value, page: 1 }
    };
  case UPDATE_ENDED_ORDERS_FILTERS:
    return {
      ...state, endedOrdersFilterValues: {
        ...state.endedOrdersFilterValues, [action.key]:
                    action.value, page: 1
      }
    };
  case UPDATE_SIGNED_ORDERS_FILTERS:
    return {
      ...state, signedOrdersFilterValues: {
        ...state.signedOrdersFilterValues, [action.key]:
                    action.value, page: 1
      }
    };
  case RETRIEVE_OPEN_ORDERS:
    return { ...state, openOrders: action.payload.items, pagination: action.payload.pagination };
  case RETRIEVE_DELIVERED_ORDERS:
    return { ...state, deliveredOrders: action.payload.items, pagination: action.payload.pagination };
  case RETRIEVE_ENDED_ORDERS:
    return { ...state, endedOrders: action.payload.items, pagination: action.payload.pagination };
  case RETRIEVE_ORDER:
    return { ...state, order: action.payload };
  case UPDATE_LOADING:
    return { ...state, isLoading: action.payload };
  case UPDATE_ERROR_MESSAGE:
    return { ...state, errorMessage: action.payload };
  case UPDATE_CHECKED:
    return { ...state, checked: action.payload };
  case CLEAR_FILTERS:
    // eslint-disable-next-line no-case-declarations
    const organisations = getOrganisations();
    // eslint-disable-next-line no-case-declarations
    const resetFiltersOpen = { ...initialState.openOrdersFilterValues, organisation: organisations[0].id };
    // eslint-disable-next-line no-case-declarations
    const resetFiltersEnded = { ...initialState.endedOrdersFilterValues, organisation: organisations[0].id };
    // eslint-disable-next-line no-case-declarations
    const resetFiltersDelivered = {
      ...initialState.deliveredOrdersFilterValues,
      organisation: organisations[0].id
    };
    // eslint-disable-next-line no-case-declarations
    const resetFiltersSigned = { ...initialState.signedOrdersFilterValues, organisation: organisations[0].id };
    return {
      ...state,
      openOrdersFilterValues: { ...resetFiltersOpen },
      endedOrdersFilterValues: { ...resetFiltersEnded },
      deliveredOrdersFilterValues: { ...resetFiltersDelivered },
      signedOrdersFilterValues: { ...resetFiltersSigned }
    };
  case RETRIEVE_ORDERS_TO_SIGN:
    return { ...state, ordersToSign: action.payload.items, pagination: action.payload.pagination };
  default:
    return state;
  }
};

export default ordersReducer;
