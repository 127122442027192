import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { thunk } from 'redux-thunk';
import * as hist from 'history';
const createHistory = hist.createBrowserHistory;
import rootReducer from './rootReducer';

const historyOpts = {};
const initialState = {};
const enhancers = [];
export const history = createHistory(historyOpts);
const middleware = [
  thunk,
  routerMiddleware(history)
];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);
const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store;
