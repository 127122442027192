import { createTheme } from "@mui/material";
import {
  Alert,
  Black,
  OffGray,
  GrayLight,
  Primary,
  PrimaryDark,
  Success,
  White,
  Warning,
} from "../constants/colors";
import { Helvetica, HelveticaBold } from "../fonts";
import { CustomExpandIcon } from "../components/UI/Icons";
import React from "react";

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: Primary, // primary
      contrastText: White,
    },
    secondary: {
      main: PrimaryDark, // primary-hover
      contrastText: White,
    },
    background: {
      paper: White,
      default: GrayLight, // gray-light
    },
    text: {
      primary: Black,
    },
    info: {
      main: PrimaryDark,
    },
    success: {
      main: Success,
    },
    warning: {
      main: Warning,
    },
    error: {
      main: Alert,
      contrastText: White,
    }
  },
  typography: {
    fontFamily: Helvetica,
    fontSize: 16,
    h1: {
      fontSize: 30,
      fontWeight: 300,
    },
    h6: {
      fontFamily: HelveticaBold,
      fontSize: 18,
      fontWeight: 600,
    },
    p: {
      fontFamily: Helvetica,
      fontSize: 15,
      fontWeight: "normal",
    },
    body1: {
      fontSize: 18,
      fontWeight: "normal"
    },
    button: {
      fontWeight: 400,
      fontSize: 18,
      textTransform: 'none',
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: "outlined",
        sx: {
          padding: "20px",
          borderRadius: "3px",
          borderWidth: "0px"
        }
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
        sx: {
          borderColor: "currentColor",
          borderRadius: "3px",
          borderWidth: "1.5px",
          "&:hover": {
            borderWidth: "1.5px",
            borderColor: "currentColor"
          },
        }
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableElevation: true,
        variant: "contained",
        sx: {
          borderColor: "currentColor",
          borderRadius: "3px",
          borderWidth: "1.5px",
          "&:hover": {
            borderWidth: "1.5px",
            borderColor: "currentColor"
          },
        }
      },
    },
    MuiStepper: {
      defaultProps: {
        alternativeLabel: true,
      },
    },
    MuiSelect: {
      defaultProps: {
        required: true,
        fullWidth: true,
      }
    },
    MuiCheckbox: {
      color: "success",
    },
    MuiTextField: {
      defaultProps: {
        required: true,
        variant: "outlined",
        fullWidth: true,
        margin: "normal",
        sx: {
          borderRadius: "2px"
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        component: "div",
      },
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <CustomExpandIcon/>,
      }
    },
    MuiRadio: {
      defaultProps: {
        required: true,
        color: "success",
        sx: {
          fontSize: "18px",
          color: OffGray,
          transition: "color 200ms ease-in-out",
          "&.Mui-checked": {
            color: Success,
            "&.Mui-disabled": {
              opacity: "20%",
            },
          },
          "&:hover": {
            color: Success
          },
          "&.Mui-disabled": {
            opacity: "30%",
          }
        },
      },
    },
    MuiFormControlLabel: {
      defaultProps: {
        color: "success",
        sx: {
          "&.Mui-checked": {
            "&.Mui-disabled": {
              opacity: "30%",
              color: Success
            },
          },
        },
      },
    },
    MuiTableContainer: {
      defaultProps: {
        sx: {
          padding: "17px 0 0 0"
        }
      }
    },
    MuiTableHead: {
      defaultProps: {
        sx: {
          borderBottom: "none",
          fontFamily: Helvetica,
          lineHeight: "28px",
          opacity: "60%",
          fontSize: "18px"
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        size: "small",
        sx: {
          borderBottom: "none",
          fontFamily: Helvetica,
          lineHeight: "normal",
        },
      },
    },
    MuiChip: {
      defaultProps: {
        sx: {
          borderRadius: "4px",
          height: "unset",
          fontFamily: HelveticaBold,
        }
      }
    },
    MuiAlert: {
      defaultProps: {
        variant: "filled",
        role: "alert",
        sx: {
          fontSize: "0.875rem"
        }
      }
    },
  },
});
