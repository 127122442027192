// ------------------------------------
// Constants
// ------------------------------------

// TODO: put this in a .env file
export const API_BASE_URL =
  window.location.hostname === 'localhost'
    ? 'http://localhost:6060/api/v1'
    : window.location.hostname.includes('tst')
      ? 'https://be.klantenportaal-tst.hartingbank.nl/api/v1'
      : window.location.hostname.includes('uat')
        ? 'https://be.klantenportaal-uat.hartingbank.nl/api/v1'
        : 'https://be.klantenportaal.hartingbank.nl/api/v1';

export const API_REQUEST_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Origin: '*',
};

export const API_REQUEST_OBJECT = {
  mode: 'cors',
  headers: API_REQUEST_HEADERS,
  withCredentials: true
};

export const GTMTrackingCode = "GTM-N4S7BJV3";
