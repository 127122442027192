import { API_BASE_URL, API_REQUEST_OBJECT } from '../constants/api';

/**
 * Get maintenance status and text
 *
 * @returns {Promise<any>}
 */
export const checkMaintenance = async () => {
  const requestObj = {
    ...API_REQUEST_OBJECT,
    method: 'GET',
    headers: {
      ...API_REQUEST_OBJECT.headers
    }
  };
  const response = await fetch(`${API_BASE_URL}/maintenance`, requestObj);
  return await response.json();
};