export const UPDATE_SELECTED_ORG = 'UPDATE_SELECTED_ORG';

export const getSelectedOrg = (payload) => ({
  type: UPDATE_SELECTED_ORG,
  payload
});

export const updateSelectedOrg = (organisation) => {
  return (dispatch) => {
    dispatch(getSelectedOrg(organisation));
  };
};

export const initialState = {
  selectedOrganisation: 0,
};

export const selectedOrganisationsReducer = (state = initialState, action) => {  
  switch (action.type) {
  case UPDATE_SELECTED_ORG:
    return { ...state, selectedOrganisation: action.payload };
  default:
    return state;
  }
};

export default selectedOrganisationsReducer;
