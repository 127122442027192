import {
  setMe,
  setOrganisations,
  setLocations
} from '../assets/common/common';
import { addError } from './errors';
import apiClient from '../assets/common/interceptor';

// define types here
export const LOGIN_CHANGE = 'LOGIN_CHANGE';
export const GET_ME_CHANGE = 'GET_ME_CHANGE';
export const ERROR_CHANGE = 'ERROR_CHANGE';

export function getMeChange (payload) {
  return {
    type: GET_ME_CHANGE,
    payload
  };
}

export function updateError (payload) {
  return {
    type: ERROR_CHANGE,
    payload
  };
}

// define API calls here
export const getMe = () => {
  return (dispatch) => {
    return apiClient
      .get("/azure/me")
      .then((response) => {
        return Promise.resolve(response.status === 204 ? {} : response.data);
      })
      .then((response) => {
        const data = response;
        if (
          data &&
          typeof data === 'object' &&
          !{}.hasOwnProperty.call(data, 'error')
        ) {
          const organisations = [];
          const locations = [];
          data.organisations?.map((val) => {
            organisations.push({ name: val.name, id: val.id, locations: val.locations });
            locations.push({ id: val.id, locations: val.locations });
          });

          setOrganisations(organisations.length > 0 ? organisations : []);

          setLocations(locations.length > 0 ? locations : []);

          dispatch(
            getMeChange({
              authenticated: true,
              me: data,
            })
          );
          if (window.location.pathname.startsWith("/login")) {
            window.location.href = "/";
          }
          return Promise.resolve(data);
        } else {
          dispatch(
            getMeChange({
              authenticated: false,
              me: null
            })
          );
          // If there was a problem...
          return Promise.reject(data);
        }
      })
      .catch(() => {
        // if there is a problem getting the user, log the user out.
      });
  };
};

export const updateMe = (data) => {
  // Props are the data that you want to change in object and getMe()
  return (dispatch) => {
    const body = {
      phonenumber_for_questions: data.phonenumber_for_questions,
      function: data.function,
      favorite_locations: data.favorite_locations
    };

    return apiClient.post('/userSettings', body)
      .then((data) => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem
          return Promise.reject(data.data);
        }
      })
      .catch((err) => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = '/';
        if (typeof err === 'object' && typeof err.then === 'function') {
          err.then((e) => {
            dispatch(addError(e.message));
          });
        }
      });
  };
};

export const initialState = {
  authenticated: null,
  message: '',
  error: false,
  me: null
};

export default function authenticationReducer (state = initialState, action) {
  switch (action.type) {
  case LOGIN_CHANGE:
    return { ...state };
  case GET_ME_CHANGE:
    setMe(action.payload.me);
    return {
      ...state,
      me: action.payload.me,
      authenticated: action.payload.authenticated
    };
  case ERROR_CHANGE:
    return { ...state, message: action.payload.message, error: action.payload.error };
  default:
    return state;
  }
}
