import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert, AlertTitle } from "@mui/material";

const CustomAlert = (props) => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);

    // Only when alert has an ID (RentalRequest flow)
    if (props?.id) {
      props.clearError && props.clearError(props.id);
    }
  };

  return (
    <Snackbar
      open={open}
      className={props.type}
      autoHideDuration={props.autoHide ? 10000 : null }
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Alert
        onClose={() => handleClose()}
        severity={props.severity || "error"}
        variant="filled"
        role="alert"
      >
        {props.title && <AlertTitle>{props.title}</AlertTitle>}
        {props.msg}
      </Alert>
    </Snackbar>
  );
};

CustomAlert.propTypes = {
  id: PropTypes.string,
  clearError: PropTypes.func,
  title: PropTypes.string,
  msg: PropTypes.string,
  type: PropTypes.string,
  severity: PropTypes.string,
  autoHide: PropTypes.bool,
}; 

export default CustomAlert;
