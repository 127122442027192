// Imports
import * as React from "react";
import * as Yup from "yup";
import { Alert, Chip } from "@mui/material";
import PropTypes from "prop-types";
import { parse } from "date-fns";
import moment from "moment";
import apiClient from "./interceptor";

/* ------------------------------------------- */
// General
/* ------------------------------------------- */
export const isMobile = () => {
  // TODO Note: userAgent will be deprecated
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isTablet = () => {
  return window.innerWidth < 768.9;
};

export const isDesktop = () => {
  return window.innerWidth > 1024;
};

export const dateFormat = "DD/MM/YYYY";

export const generalErrorMessage = (props) => {
  if (props.errorMessage > 0) {
    return <Alert severity="info">{props.errorMessage}</Alert>;
  }
};

export const ConditionalChips = (props) => {
  switch (props.deploymentPeriod) {
  case "short":
    return <Chip label={props.value} color="success"/>;
  case "medium":
    return <Chip label={props.value} color="warning"/>;
  case "long":
    return <Chip label={props.value} color="error"/>;
  default:
    return <Chip label="N/A" color="error"/>;
  }
};

ConditionalChips.propTypes = {
  value: PropTypes.number,
  deploymentPeriod: PropTypes.string,
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/* ------------------------------------------- */
// START of Get and set SessionStorage
/* ------------------------------------------- */
export const StorageKeys = {
  ME: "me",
  STEP: "step",
  RENTAL_REQUEST_CONFIG: "rentalRequestConfig",
  PRODUCT_CONFIG: "productConfig",
  LOCATIONS: "locations",
  ORGANISATIONS: "organisations",
  SELECTED_ORDER: "selectedOrder",
  PRELOAD_PDF_CONFIG: "preloadPDFConfig"
};

export const setStorageItem = (key, value) => {
  return sessionStorage.setItem(key, value);
};

export const getLocalStorageItem = (key) => {
  return localStorage.getItem(key);
};

export const clearLocalStorageItem = (key) => {
  return localStorage.removeItem(key);
};

export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(key, value);
};

export const getStorageItem = (key) => {
  return sessionStorage.getItem(key);
};

export const clearStorageItem = (key) => {
  return sessionStorage.removeItem(key);
};

export const clearProductConfigOptions = () => {
  const object = getRentalRequestConfig();
  delete object.productConfig;
  setRentalRequestConfig(object);
};

export const setSteps = (step) => {
  setStorageItem(StorageKeys.STEP, JSON.stringify(step));
};

export const getSteps = () => {
  return JSON.parse(getStorageItem(StorageKeys.STEP)) || 0;
};

export const setRentalRequestConfig = (config) => {
  setStorageItem(StorageKeys.RENTAL_REQUEST_CONFIG, JSON.stringify(config));
};

export const setPreloadPDFConfig = (config) => {
  setStorageItem(StorageKeys.PRELOAD_PDF_CONFIG, JSON.stringify(config));
};

export const getPreloadPDFConfig = () => {
  return JSON.parse(getStorageItem(StorageKeys.PRELOAD_PDF_CONFIG)) || {};
};

export const clearPreloadPDFConfig = () => {
  clearStorageItem(StorageKeys.PRELOAD_PDF_CONFIG);
};

export const setSelectedOrderConfig = (config) => {
  setStorageItem(StorageKeys.SELECTED_ORDER, JSON.stringify(config));
};

export const setMe = (me) => {
  setStorageItem(StorageKeys.ME, JSON.stringify(me));
};

export const getMe = () => {
  return JSON.parse(getStorageItem(StorageKeys.ME)) || {};
};

export const setLocations = (me) => {
  setStorageItem(StorageKeys.LOCATIONS, JSON.stringify(me));
};

export const getLocations = () => {
  return JSON.parse(getStorageItem(StorageKeys.LOCATIONS)) || {};
};

export const setOrganisations = (me) => {
  setStorageItem(StorageKeys.ORGANISATIONS, JSON.stringify(me));
};

export const getOrganisations = () => {
  return JSON.parse(getStorageItem(StorageKeys.ORGANISATIONS)) || {};
};

export const getRentalRequestConfig = () => {
  return JSON.parse(getStorageItem(StorageKeys.RENTAL_REQUEST_CONFIG)) || {};
};

export const getSelectedOrder = () => {
  return JSON.parse(getStorageItem(StorageKeys.SELECTED_ORDER)) || {};
};

export const clearRentalOrder = () => {
  clearStorageItem(StorageKeys.RENTAL_REQUEST_CONFIG);
  clearStorageItem(StorageKeys.STEP);
};

// Temporary 'Fix' to circumvent the roles issues on the testing environment
export const checkTestEnvironment = () => {
  return window.location.href.indexOf("localhost") > -1;
};

/* ------------------------------------------- */
// ROLES
/* ------------------------------------------- */
export const me = getMe();
export const role = getMe()?.bbt_role;
export const roleAllRights = "alle rechten";
export const roleRepairsOnly = "alleen reparaties";
export const roleViewerOnly = "alleen inzage";
export const roleNoSigning = "excl. tekenen";
export const roleNoChangeRequests = "excl. aanvragen/aanpassen";

/* ------------------------------------------- */
// END of ROLES
/* ------------------------------------------- */

export const flowFormYup = Yup.object({
  reason: Yup.string()
    .required("Geef het defect aan"),
  department_room_nr: Yup.string()
    .required("Vul afdeling en/of kamernummer in"),
  locationName: Yup.string()
    .required("Vul locatie in"),
});

export const flowChangeFormYup = Yup.object({
  reason: Yup.string()
    .required("Geef de aanpassing aan"),
  department_room_nr: Yup.string()
    .required("Vul afdeling en/of kamernummer in"),
  locationName: Yup.string()
    .required("Vul locatie in"),
});

export const flowFormRelocationYup = Yup.object({
  organisation: Yup.string()
    .required("Verplicht"),
  location: Yup.string()
    .required("Verplicht"),
  department: Yup.string()
    .required("Verplicht"),
  room_nr: Yup.number()
    .required("Verplicht"),
  dateChange: Yup.date()
    .transform((value, originalValue) => parse(originalValue, 'dd/MM/yyyy', new Date()))
    .required("Vul uw geboortedatum in")
    .typeError("Voer een geldige geboortedatum in"),
});

// Translate gender
export const genderTranslation = (gender) => {
  switch (gender) {
  case "notSpecified":
    return "Niet gespecificeerd";
  case "unknown":
    return "Niet gespecificeerd";
  case "female":
    return "Vrouw";
  case "male":
    return "Man";
  default:
    return "Nog niet bekend";
  }
};

export const revertDate = (dateInput) => {
  // If date is dd-mm-yyyy
  if (dateInput.includes("-")){
    return dateInput.split('-').reverse().join('-');
  }
  // If date is dd/mm/yyyy
  if (dateInput.includes("/")){
    return dateInput.split('/').reverse().join('/');
  }

};

/* ------------------------------------------- */
// DOWNLOAD PDF
/* ------------------------------------------- */
// Template IDs to determine which template should be used
export const templateIDServiceRequest = "pdf_service_request";
export const templateIDRentalRequest = "pdf_rental_request";

/* ------------------------------------------- */
// ORDER FILTERS
/* ------------------------------------------- */
export const allFilters = [
  {
    type: "organisation",
    title: "Organisatie",
    options: []
  },
  {
    type: "locationName",
    title: "Locatie",
    options: []
  },
  {
    type: "orderType",
    title: "Type aanvraag",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Leveringen",
        value: "rentalOrder"
      },
      {
        label: "Naleveringen",
        value: "backOrder"
      },
      {
        label: "Ophaalopdracht",
        value: "pickupRequest"
      },
      {
        label: "Reparaties",
        value: "repairRequest"
      },
      {
        label: "Aanpassingen",
        value: "changeRequest"
      },
    ]
  },
  {
    type: "actionType",
    title: "Actie zorgprofessional",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Geen actie nodig",
        value: "noAction"
      },
      {
        label: "Wacht op aanvraag zorgkantoor",
        value: "zorginfo"
      },
      {
        label: "Wacht op ondertekenen",
        value: "signing"
      },
    ]
  },
  {
    type: "locationService",
    title: "Soort dienst",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "AD",
        value: "AD"
      },
      {
        label: "ELV",
        value: "ELV"
      },
      {
        label: "GRZ",
        value: "GRZ"
      },
      {
        label: "WLZ",
        value: "WLZ"
      },
      {
        label: "VLR",
        value: "VLR"
      },
    ]
  },
  {
    type: "executionDate",
    title: "Uitvoerdatum",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Huidige week",
        value: "currentWeek"
      },
      {
        label: "2 weken",
        value: "twoWeeks"
      },
      {
        label: "Maand",
        value: "month"
      },
      {
        label: "Nog onbekend",
        value: "unknown"
      },
    ]
  },
  {
    type: "status",
    title: "Status",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Opdracht verzonden",
        value: "created"
      },
      {
        label: "In behandeling depothouder",
        value: "inProgressDepotHolder"
      },
      {
        label: "In behandeling adviseur",
        value: "inProgressAdvisor"
      },
      {
        label: "In behandeling",
        value: "inProgress"
      },
      {
        label: "Offerte wacht op goedkeuring",
        value: "quoteApproval"
      },
      {
        label: "In bestelling",
        value: "onOrder"
      },
      {
        label: "In de werkplaats",
        value: "atWorkplace"
      },
      {
        label: "Gepland",
        value: "planned"
      },
      {
        label: "Uitgevoerd",
        value: "completed"
      },
    ]
  },
];

export const deliveredFilters = [
  {
    type: "organisation",
    title: "Organisatie",
    options: []
  },
  {
    type: "locationName",
    title: "Locatie",
    options: []
  },
  {
    type: "deploymentPeriod",
    title: "Aantal dagen uitstaand",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        chip: "success",
        label: "Kort",
        value: "short"
      },
      {
        chip: "warning",
        label: "Gemiddeld",
        value: "medium"
      },
      {
        chip: "error",
        label: "Lang",
        value: "long"
      },
    ]
  },
  {
    type: "locationService",
    title: "Soort dienst",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "AD",
        value: "AD"
      },
      {
        label: "ELV",
        value: "ELV"
      },
      {
        label: "GRZ",
        value: "GRZ"
      },
      {
        label: "VLR",
        value: "VLR"
      },
    ]
  },
  {
    type: "executionDate",
    title: "Afleverdatum",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Laatste week",
        value: "lastWeek"
      },
      {
        label: "Laatste maand",
        value: "month"
      },
      {
        label: "Laatste 3 maanden",
        value: "lastThreeMonths"
      },
      {
        label: "Onbekend",
        value: "unknown"
      },
    ]
  },
];

export const endedFilters = [
  {
    type: "organisation",
    title: "Organisatie",
    options: []
  },
  {
    type: "locationName",
    title: "Locatie",
    options: []
  },
  {
    type: "locationService",
    title: "Soort dienst",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "AD",
        value: "AD"
      },
      {
        label: "ELV",
        value: "ELV"
      },
      {
        label: "GRZ",
        value: "GRZ"
      },
      {
        label: "VLR",
        value: "VLR"
      },
    ]
  },
  {
    type: "executionDate",
    title: "Einddatum",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Laatste week",
        value: "lastWeek"
      },
      {
        label: "Laatste maand",
        value: "month"
      },
      {
        label: "Laatste 3 maanden",
        value: "lastThreeMonths"
      },
      {
        label: "Onbekend",
        value: "unknown"
      },
    ]
  },
  {
    type: "orderType",
    title: "Type aanvraag",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Leveringen",
        value: "rentalOrder"
      },
      {
        label: "Ophaalopdracht",
        value: "pickupRequest"
      },
      {
        label: "Reparaties",
        value: "repairRequest"
      },
      {
        label: "Aanpassingen",
        value: "changeRequest"
      },
    ]
  }
];

export const signFilters = [
  {
    type: "organisation",
    title: "Organisatie",
    options: []
  },
  {
    type: "locationName",
    title: "Locatie",
    options: []
  },
  {
    type: "orderType",
    title: "Type aanvraag",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Leveringen",
        value: "rentalOrder"
      },
      {
        label: "Ophaalopdracht",
        value: "pickupRequest"
      },
      {
        label: "Reparaties",
        value: "repairRequest"
      },
      {
        label: "Aanpassingen",
        value: "changeRequest"
      },
    ]
  },
  {
    type: "actionType",
    title: "Actie zorgprofessional",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Wacht op ondertekenen",
        value: "signing"
      },
      {
        label: "Wacht op aanvraag zorgkantoor",
        value: "zorginfo"
      }
    ]
  },
  {
    type: "executionDate",
    title: "Uitvoerdatum",
    options: [
      {
        label: "Alle",
        value: "All"
      },
      {
        label: "Huidige week",
        value: "currentWeek"
      },
      {
        label: "2 weken",
        value: "twoWeeks"
      },
      {
        label: "Maand",
        value: "month"
      },
      {
        label: "Onbekend",
        value: "unknown"
      },
    ]
  },
];

export const checkIfChrome = () => {
  if (navigator.userAgentData) {
    const brands = navigator.userAgentData.brands;
    return brands.some(e => e.brand === 'Google Chrome' || e.brand === 'Chromium');
  } else {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }
};
export const getFileFormat = (format) => {
  switch(format){
  case "application/msword":
    return "DOC";
  case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    return "DOCX";
  case "application/pdf":
    return "PDF";
  case "image/png":
    return "PNG";
  case "image/jpeg":
    return "JPG";
  case "application/vnd.ms-excel":
    return "XLS";
  case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    return "XLSX";
  case "text/plain":
    return "TXT";
  case "text/csv":
    return "CSV";
  case "application/octet-stream":
    return "DAT";
  case "image/bmp":
    return "BMP";
  case "application/vnd.ms-outlook":
    return "MSG";
  case "application/xml":
    return "XML";
  case "text/html":
    return "HTML";
  case "message/rfc822":
    return "EML";
  default:
    return "TEXT";
  }
};

export const getDateToSort = (type) => {
  if(type === 'open'){
    return 'expectedExecutionDate';
  }
  if(type === 'ended'){
    return 'endDate';
  }
  if(type === 'delivered'){
    return 'executionDate';
  }
};

export const getSortAndDirection = (sort, type = 'open') => {
  const dateToSort = getDateToSort(type);
  switch (sort) {
  case "date_ascending":
    return {
      "direction": "ASC",
      "sort":dateToSort
    };
  case "date_descending":
    return {
      "direction": "DESC",
      "sort":dateToSort
    };
  case "client_ascending":
    return {
      "direction": "ASC",
      "sort":"fullName"
    };
  case "client_descending":
    return {
      "direction": "DESC",
      "sort":"fullName"
    };
  default:
    return {

    };
  }
};

export const sortOptions = (type) => {
  switch (type) {
  case "uitstaande-huurmiddelen":
    return (
      [
        {
          value: 'date_descending',
          label: 'Afleverdatum (Nieuw - Oud)',
        },
        {
          value: 'date_ascending',
          label: 'Afleverdatum (Oud - Nieuw)',
        },
        {
          value: 'client_ascending',
          label: 'Cliënt (A-Z)',
        },
        {
          value: 'client_descending',
          label: 'Cliënt (Z-A)',
        },
      ]
    );
  case "beeindigde-trajecten":
    return (
      [
        {
          value: 'date_descending',
          label: 'Einddatum (Nieuw - Oud)',
        },
        {
          value: 'date_ascending',
          label: 'Einddatum (Oud - Nieuw)',
        },
        {
          value: 'client_ascending',
          label: 'Cliënt (A-Z)',
        },
        {
          value: 'client_descending',
          label: 'Cliënt (Z-A)',
        },
      ]
    );
  default:
    return (
      [
        {
          value: 'date_descending',
          label: 'Uitvoerdatum (Nieuw - Oud)',
        },
        {
          value: 'date_ascending',
          label: 'Uitvoerdatum (Oud - Nieuw)',
        },
        {
          value: 'client_ascending',
          label: 'Cliënt (A-Z)',
        },
        {
          value: 'client_descending',
          label: 'Cliënt (Z-A)',
        },
      ]
    );
  }
};

export const generatePDFBody = (values, templateID) => {
  return {
    templateId: templateID,
    templateContent: {
      orderDate: "-", // Data is not yet being received
      locationName: values?.order?.location || "-",
      locationService: values?.order?.product.locationService || "-",
      delivery: {
        instruction: values.order?.deliveryInstruction || "-" // Data is not yet being received
      },
      department: values?.order?.department || "-",
      client: {
        fullName: values?.order?.client || "-",
        gender: genderTranslation(values?.order?.gender) || "-",
        birthDate: moment(values?.order?.birthDate).format("DD-MM-YYYY") || "-"
      },
      product: {
        subcategory: values?.order?.product.subcategory || "-",
        referenceId: values?.order?.product.referenceId || "-"
      },
      producttype: values?.order?.provision || "-",
      orderType: values?.order?.orderType || "-",
      productUID: values.order?.provisionNumber || "-"
    },
    orderId: values?.order?.id || ""
  };
};

export const generatePDF = async (data) => {
  const response = apiClient.post('/organisation/pdf', data);
  return response;
};
