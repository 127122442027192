
export const ORGANISATION_CHANGE = 'ORGANISATION_CHANGE';

export function setOrganisations (payload) {
  return ({
    type: ORGANISATION_CHANGE,
    payload
  });
}

export const initialState = {
  organisations: [],
};

export default function organisationsReducer (state = initialState, action) {
  switch (action.type) {
  case ORGANISATION_CHANGE:
    return { ...state, organisations: action.payload };
  default:
    return state;
  }
}
