import apiClient from "../assets/common/interceptor";

export const PRODUCTS_CHANGE = 'PRODUCTS_CHANGE';
export const PRODUCT_CHANGE = 'PRODUCT_CHANGE';
export const VIEW_USER = 'VIEW_USER';

export function productsChange (payload) {
  return ({
    type: PRODUCTS_CHANGE,
    payload
  });
}

export function productChange (payload) {
  return ({
    type: PRODUCT_CHANGE,
    payload
  });
}

export const getProducts = (service) => {
  return (dispatch) => {
    return apiClient.get(`/products?locationType=${service}`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        if (data && typeof data === 'object' && data.status !== false) {
          dispatch(productsChange(data));
          return Promise.resolve(data);
        } else {
          // If there was a problem...
          return Promise.reject(data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        console.error(err);
      });
  };
};

export const getProduct = (id, organisationId, locationName) => {
  return (dispatch) => {
    return apiClient.get(`/product/${id}?organisationId=${organisationId}&locationName=${locationName}`)
      .then(response => {
        return response.data;
      })
      .then(data => {
        if (data && typeof data === 'object' && data.status !== false) {
          dispatch(productChange(data));
          return Promise.resolve(data);
        } else {
          // If there was a problem...
          return Promise.reject(data);
        }
      })
      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        console.error(err);
      });
  };
};

export const initialState = {
  products: [
    {
      id: null,
      name: '',
      discription: '', // 😭
      short_description: '',
      image: '',
    }
  ],
  product: [
    {
      id: null,
      name: '',
      discription: '', // 😭
      short_description: '',
      image: '',
      options: [
        {
          id: null,
          name: '',
          option_key: '',
          short_description: '',
          input_type: '',
          values: [{
            id: null,
            option_id: null,
            value: '',
            ad_restriction: null,
            is_default: null
          }]

        }
      ],
      specifications: [
        {
          id: null,
          specification: '',
          product_id: null
        }
      ]
    }
  ]
};

export default function productsReducer (state = initialState, action) {
  switch (action.type) {
  case PRODUCTS_CHANGE:
    return { ...state, products: action.payload };
  case PRODUCT_CHANGE:
    return { ...state, product: action.payload };
  case VIEW_USER:
    return { ...state, user: action.payload.data.user };
  default:
    return state;
  }
}
