import { useEffect } from 'react';
import GA4React from 'ga-4-react';
import { useSelector } from 'react-redux';

/**
 * Hook to send page user data to GA4React, including user login status.
 */
const useGA4PageUserData = () => {
  const user = useSelector((state) => state?.authentication?.me);
  const selectedOrganisation = useSelector(state => state?.selectedOrganisationsReducer?.selectedOrganisation);

  useEffect(() => {
    // Check if GA4React is already initialized
    if (GA4React.isInitialized()) {
      try {
        window.dataLayer = window.dataLayer || [];

        // Push user data to data layer
        window.dataLayer.push({
          event: 'User Data',
          user_email: user?.preferred_username,
          user_login_status: user ? 'Logged in' : 'Logged out',
          user_id: user?.id,
          user_type: user?.bbt_role,
          user_organization: user?.organisations?.find((org) => org?.id === selectedOrganisation)?.name 
            || user?.organisations?.[0]?.name
        });
      } catch (error) {
        console.error('Error sending page user data:', error);
      }
    } else {
      console.error('GA4React is not initialized. Ensure it is initialized in index.js');
    }
  }, [selectedOrganisation, user]);

  return null; // This hook doesn't return anything
};

export default useGA4PageUserData;
