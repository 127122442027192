//all often used colors
export const Primary = '#02b9e3';
export const PrimaryDark = '#1694b4';
export const Secondary = '#007481';
export const GrayDirty = '#e5d4cd';
export const GrayDirtyHover = '#cdb6a7';
export const Gray = '#dddee0';
export const GrayLight = '#f2f2f2';
export const OffGray = '#b6b6b6';
export const Success = '#00b092';
export const Warning = '#f78924';
export const Error = '#f4511e';
export const Alert = Error;
export const Background = GrayLight;

// Text colors
export const Black = '#000';
export const White = "#FFF";