import axios from 'axios';
import { API_BASE_URL, API_REQUEST_OBJECT } from '../../constants/api';

// Create an Axios Instance with a base configuration
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  ...API_REQUEST_OBJECT,
});

// Add a request interceptor
apiClient.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Handle response
apiClient.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response
    && ([401, 403].includes(error.response.status))
    && !window.location.pathname.startsWith("/login")) {
    window.location.href = "/login";
  } else {
    return Promise.reject(error);
  }
});

export default apiClient;
