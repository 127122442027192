import loadable from "@loadable/component";

const Home = loadable(() => import(/* webpackPrefetch: true */ "./Home"));
const Profile = loadable(() => import(/* webpackPrefetch: true */ "./Profile"));
const ThankYou = loadable(() => import(/* webpackPrefetch: true */ "./ThankYou"));
const Login = loadable(() => import(/* webpackPrefetch: true */ "./Login/Login"));
const ErrorPage = loadable(() => import(/* webpackPrefetch: true */ "./ErrorPage/ErrorPage"));
const FavoriteLocations = loadable(() =>
  import(/* webpackPrefetch: true */ "./FavoriteLocations"));

// ServiceRequest
const RentalRequest = loadable(() => import(/* webpackPrefetch: true */ "./RentalRequest"));
const RepairRequest = loadable(() => import(/* webpackPrefetch: true */ "./ServiceRequest/RepairRequest"));
const ChangeRequest = loadable(() => import(/* webpackPrefetch: true */ "./ServiceRequest/ChangeRequest"));
const PickUpRequest = loadable(() => import(/* webpackPrefetch: true */ "./ServiceRequest/PickUpRequest"));
const SearchRequest = loadable(() => import(/* webpackPrefetch: true */ "./ServiceRequest/SearchRequest"));
const RelocationRequest = loadable(() =>
  import(/* webpackPrefetch: true */ "./ServiceRequest/RelocationRequest"));

// Actions
const SignItems = loadable(() => import(/* webpackPrefetch: true */ "./SignItems"));

// Overview
const OverviewPage = loadable(() =>
  import(/* webpackPrefetch: true */ './OverviewPage/OverviewPage')
);

export {
  Home,
  Profile,
  ThankYou,
  Login,
  RentalRequest,
  RepairRequest,
  ChangeRequest,
  PickUpRequest,
  SignItems,
  SearchRequest,
  RelocationRequest,
  OverviewPage,
  ErrorPage,
  FavoriteLocations
};
