import {
  Home,
  Profile,
  RentalRequest,
  RepairRequest,
  ChangeRequest,
  PickUpRequest,
  ThankYou,
  Login,
  SearchRequest,
  RelocationRequest,
  OverviewPage,
  FavoriteLocations,
  ErrorPage
} from "../views";

export const routes = [
  { path: "/", exact: true, component: Home, auth: true, title: "Home" },
  { path: "/login", exact: true, component: Login, auth: false, title: "Login" },
  { path: "/huurmiddel-aanvragen/*", component: RentalRequest, auth: true, title: "Huurmiddel aanvragen" },

  //  Action pages.scss
  { path: "/reparatie-aanvragen/:service/:id", component: RepairRequest, auth: true, title: "Reparatie aanvragen" },

  //  Service request
  { path: "/aanpassing-aanvragen/:service/:id", component: ChangeRequest, auth: true, title: "Aanpassing aanvragen" },
  { path: "/huurmiddel-afmelden/:service/:id", component: PickUpRequest, auth: true, title: "Huurmiddel afmelden" },
  { path: "/reparatie-aanvragen", component: SearchRequest, auth: true, title: "Reparatie aanvragen" },

  { path: "/verplaatsing-doorgeven", component: RelocationRequest, auth: true, title: "Verplaatsing doorgeven" },
  { path: "/aanpassing-aanvragen", component: SearchRequest, auth: true, title: "Aanpassing aanvragen" },
  { path: "/overzichten/:page", component: OverviewPage, auth: true, title: "Overzichten" },

  // Overview pages.scss
  { path: "/profiel", component: Profile, auth: true, title: "Profiel" },

  // Other
  { path: "/favoriete-locaties", component: FavoriteLocations, auth: true, title: "Favoriete locaties" },
  { path: "/bedankt", component: ThankYou, auth: true, title: "Bedankt" },

  // Not found handlers
  { path: "/404", component: ErrorPage, title: "Pagina niet gevonden", auth: false },
  { path: "/error", component: ErrorPage, title: "Er ging iets mis aan onze kant", auth: false },
  { path: "*", component: ErrorPage, title: "Pagina niet gevonden", auth: false },
];

export const routesRepair = [
  { path: "/", exact: true, component: Home, auth: true, title: "Home" },
  { path: "/login", exact: true, component: Login, auth: false, title: "Login" },

  { path: "/reparatie-aanvragen/:service/:id", component: RepairRequest, auth: true, title: "Reparatie aanvragen" },

  { path: "/reparatie-aanvragen", component: SearchRequest, auth: true, title: "Reparatie aanvragen" },

  // Other
  { path: "/profiel", component: Profile, auth: true, title: "Profiel" },
  { path: "/favoriete-locaties", component: FavoriteLocations, auth: true, title: "Favoriete locaties" },
  { path: "/bedankt", component: ThankYou, auth: true, title: "Bedankt" },
];
export const routesView = [
  { path: "/", exact: true, component: Home, auth: true, title: "Home" },
  { path: "/login", exact: true, component: Login, auth: false, title: "Login" },

  // Overview pages.scss
  { path: "/overzichten/:page", component: OverviewPage, auth: true, title: "Overzichten" },

  // Other
  { path: "/profiel", component: Profile, auth: true, title: "Profiel" },
  { path: "/favoriete-locaties", component: FavoriteLocations, auth: true, title: "Favoriete locaties" },
  { path: "/bedankt", component: ThankYou, auth: true, title: "Bedankt" },
];

export const routesEdit = [
  { path: "/", exact: true, component: Home, auth: true, title: "Home" },
  { path: "/login", exact: true, component: Login, auth: false, title: "Login" },

  // Service request
  { path: "/reparatie-aanvragen/:service/:id", component: RepairRequest, auth: true, title: "Reparatie aanvragen" },
  { path: "/huurmiddel-afmelden/:service/:id", component: PickUpRequest, auth: true, title: "Huurmiddel afmelden" },

  { path: "/reparatie-aanvragen", component: SearchRequest, auth: true, title: "Reparatie aanvragen" },
  { path: "/overzichten/:page/huurmiddel-afmelden/", component: OverviewPage, auth: true, title: "Overzichten" },

  // Overview pages
  { path: "/overzichten/:page", component: OverviewPage, auth: true, title: "Overzichten" },

  // Other
  { path: "/profiel", component: Profile, auth: true, title: "Profiel" },
  { path: "/favoriete-locaties", component: FavoriteLocations, auth: true, title: "Favoriete locaties" },
  { path: "/bedankt", component: ThankYou, auth: true, title: "Bedankt" },
];

export const routesProfile = [
  { path: "/", exact: true, component: Home, auth: true, title: "Home" },
  { path: "/login", exact: true, component: Login, auth: false, title: "Login" },
  { path: "/profiel", component: Profile, auth: true, title: "Profiel" },
  { path: "/favoriete-locaties", component: FavoriteLocations, auth: true, title: "Favoriete locaties" },
];
