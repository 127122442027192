// ------------------------------------
// Imports
// ------------------------------------

// ------------------------------------
// Constants
// ------------------------------------
export const EXAMPLE_CONSTANT = 'EXAMPLE_CONSTANT';
// ------------------------------------
// Actions
// ------------------------------------
export const setExampleAction = (payload) => ({
  type: EXAMPLE_CONSTANT,
  payload
});
// ------------------------------------
// API calls
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  exampleState: ''
};

export const exampleReducer = (state = initialState, action) => {
  switch(action.type) {
  case EXAMPLE_CONSTANT:
    return { ...state, exampleState: action.payload };
  default: return state;
  }
};

export default exampleReducer;
