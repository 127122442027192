import { combineReducers } from 'redux';

// enchancers

// reducers
import exampleStore from "./exampleStore";
import productsReducer from './products';
import organisationsReducer from './organisations';
import stepsReducer from './steps';
import authentication from './authentication';
import rentalRequest from './rentalRequest';
import ordersReducer from './orders';
import errorReducer from './errors';
import serviceRequestReducer from './serviceRequest';
import selectedOrganisationsReducer from './selectedOrganisation';
import pdfGenerationReducer from "./generatePdfRentalRequest";

export default combineReducers({
  exampleStore,
  productsReducer,
  organisationsReducer,
  selectedOrganisationsReducer,
  stepsReducer,
  authentication,
  rentalRequest,
  ordersReducer,
  errors: errorReducer,
  serviceRequest: serviceRequestReducer,
  pdfGenerationReducer
});
