import React from "react";
import AppLayout from "./layout/AppLayout";
import { BrowserRouter as Router } from "react-router-dom";

// Tanstack Query
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';

// Redux
import { Provider } from "react-redux";
import store from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App () {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppLayout />
        </Router>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;