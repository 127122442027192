import React, { useMemo, useEffect, useState } from 'react';
import '../App.scss';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { routes, routesEdit, routesRepair, routesView, routesProfile } from '../constants/routes';
import { getMe } from '../store/authentication';
// Theming
import { ThemeProvider } from '@mui/material';
import { theme } from "./Theme";

// Components
import { Navigation, Footer } from "../components";
import ErrorAlert from '../components/ErrorAlert';

// Authentication
import {
  roleAllRights,
  roleNoChangeRequests,
  roleRepairsOnly,
  roleViewerOnly,
  roleNoSigning,
  getLocalStorageItem
} from '../assets/common/common';
import CustomAlert from '../components/CustomAlert/CustomAlert';
import withAuth from '../components/AuthenticationHOC';

// Maintenance
import Maintenance from '../views/Maintenance/Maintenance';
import { checkMaintenance } from '../store/maintenance';
import useGA4PageUserData from "../hooks/useGA4PageUserData";
import { SESSION } from '../constants/common';

function AppLayout ({ me, getMe, ...props }) {
  const location = useLocation();
  useGA4PageUserData();
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [maintenanceText, setMaintenanceText] = useState({
    textHead: '',
    textBody: ''
  });

  useEffect(() => {
    checkMaintenance().then((maintenance) => {
      setIsMaintenance(maintenance.status);
      setMaintenanceText({
        textHead: maintenance.textHead,
        textBody: maintenance.textBody
      });
    });
  }, []);

  const meData = me?.['bbt_role'] ? me?.['bbt_role'].toLowerCase() : null;

  const roleMap = {
    [`${roleAllRights}`]: routes,
    [`${roleNoSigning}`]: routes,
    [`${roleRepairsOnly}`]: routesRepair,
    [`${roleViewerOnly}`]: routesView,
    [`${roleNoChangeRequests}`]: routesEdit
  };

  const customRoute = useMemo(() => {
    const routesMap = roleMap[meData] || routesProfile;

    return routesMap?.map((i) => {
      return {
        path: i.path,
        exact: i.exact,
        component: i.auth ? withAuth(i.component) : i.component,
        title: i.title,
        auth: i.auth
      };
    });
  }, [meData]);

  useEffect(() => {
    const authId = getLocalStorageItem(SESSION.AUTH_ID);

    if (!me && !authId) {
      getMe();
    }
  }, [me]);

  // Add page title to the <title>
  useEffect(() => {
    // Extract the title from the current route
    const route = routes.find(route => route.path === location.pathname);
    const defaultTitle = "HartingBank Klantenportaal - Clientgebonden Hulpmiddelen";
    const pageTitle = route ? `${route.title} - ${defaultTitle}` : defaultTitle;

    // Set the document title
    document.title = pageTitle;
  }, [location.pathname, routes]);

  return isMaintenance ? (
    <Maintenance textHead={maintenanceText.textHead} textBody={maintenanceText.textBody}/>
  ) : (
    <>
      <ThemeProvider theme={theme}>
        <div className="App">
          <main className="main">
            <Navigation/>
            <Routes>
              {customRoute.map((route, index) => (
                <Route key={index} path={route.path}
                  element={<route.component {...props} title={route.title}/>}
                />
              ))}
              <Route
                path="/tekenen-voor-akkoord"
                element={<Navigate to="/overzichten/tekenen-voor-akkoord" />}
              />
            </Routes>
            <ErrorAlert/>
            <Footer/>
          </main>

          {/* Add an error when BBT Role is empty */}
          {meData === '' && (
            <CustomAlert
              id={meData}
              severity="warning"
              msg="Aan dit account is geen juiste rol gekoppeld — neem contact op met uw HartingBank adviseur"
            />
          )}
        </div>
      </ThemeProvider>
    </>
  );
}

function mapStateToProps (state) {
  return {
    me: state.authentication.me
  };
}

AppLayout.propTypes = {
  me: PropTypes.object,
  getMe: PropTypes.func
};

export default connect(mapStateToProps, { getMe })(AppLayout);
