// ------------------------------------
// Imports
// ------------------------------------

// ------------------------------------
// Constants
// ------------------------------------
export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
// ------------------------------------
// Actions
// ------------------------------------
export const addError = (payload) => ({
  type: ADD_ERROR,
  payload
});
export const clearError = (payload) => ({
  type: CLEAR_ERROR,
  payload
});
// ------------------------------------
// API calls
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  errors: []
};

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_ERROR:
    // eslint-disable-next-line no-case-declarations
    const errors = state.errors;
    errors.push({
      id: Math.floor(Math.random() * 100000),
      msg: action.payload
    });
    return { ...state, errors: errors };
  case CLEAR_ERROR:
    return { ...state, errors: state.errors.filter(e => e.id !== action.payload) };
  default:
    return state;
  }
};

export default errorReducer;
