import loadable from "@loadable/component";

const AddIcon = loadable(() => import(/* webpackPrefetch: true */ "./AddIcon"));
const CalendarIcon = loadable(() => import(/* webpackPrefetch: true */ "./CalendarIcon"));
const CustomExpandIcon = loadable(() => import(/* webpackPrefetch: true */ "./CustomExpandIcon"));
const Hamburger = loadable(() => import(/* webpackPrefetch: true */ "./Hamburger"));
const OutboundLinkIcon = loadable(() => import(/* webpackPrefetch: true */ "./OutboundLinkIcon"));
const RemoveIcon = loadable(() => import(/* webpackPrefetch: true */ "./RemoveIcon"));
const AlertIcon = loadable(() => import(/* webpackPrefetch: true */ "./AlertIcon"));
const SizeIcon = loadable(() => import(/* webpackPrefetch: true */ "./SizeIcon"));

export {
  AddIcon,
  CalendarIcon,
  CustomExpandIcon,
  Hamburger,
  OutboundLinkIcon,
  RemoveIcon,
  SizeIcon,
  AlertIcon
};