/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { getMe } from "../store/authentication";
import { connect } from "react-redux";
import { clearLocalStorageItem, getLocalStorageItem } from "../assets/common/common";
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import apiClient from '../assets/common/interceptor';
import { useDispatch } from 'react-redux';
import { SESSION } from '../constants/common';
export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};
const mapStateToProps = (state) => ({
  authentication: state.authentication
});

const actions = { getMe };

const withAuthenticationHOC = (WrappedComponent) => {

  const HOC = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    // Check if the user is authenticated
    useEffect( () => {

      /**
       * If a session ID exists in storage, it's Azure AD authentication and the SSO process is complete
       * If an auth ID exists in storage, it's Azure AD authentication and the SSO process is not complete
       */
      const authId = getLocalStorageItem(SESSION.AUTH_ID);

      if (authId) {
        handleAuthId();
      } else {
        handleGetMe();
        setLoading(true);
      }
    }, []);

    // Get user information handler, to check for authentication
    const handleGetMe = async () => {
      setLoading(true);
      try {
        await dispatch(getMe());
      } catch (error) {

        return error;
      } finally {
        setLoading(false);
      }
    };

    const handleAuthId = async () => {
      const authId = getLocalStorageItem(SESSION.AUTH_ID);

      setLoading(true);
      // Retrieve session ID from the server using the auth ID
      await apiClient.post("/azure/session", { authId: authId }).then(response => {
        // Retrieve user information and redirect to the home page
        handleGetMe();
        return response;
      })
        .finally(() => {
          clearLocalStorageItem(SESSION.AUTH_ID);
          setLoading(false);
        });
    };

    if (loading) {
      return <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />;
    }

    // If the user is authenticated, render the wrapped component
    if (props.authentication.authenticated || props.auth === false) {
      return <WrappedComponent {...props} />;
    }

    return <></>;
  };

  return withRouter(connect(mapStateToProps, actions)(HOC));
};

const withAuth = (WrappedComponent) =>
  withRouter(connect(mapStateToProps, actions)(withAuthenticationHOC(WrappedComponent)));

export default withAuth;