import React from 'react';
import PropTypes from 'prop-types';
import CustomAlert from "../CustomAlert/CustomAlert";

const ErrorAlert = props => {
  return (
    <>
      {props.errors.map(e => {
        return (
          <CustomAlert
            key={e.id}
            clearError={props.clearError}
            {...e} {...props}
          />
        );
      })}
    </>
  );
};

ErrorAlert.propTypes = {
  id: PropTypes.string,
  errors: PropTypes.array,
  clearError: PropTypes.func,
  msg: PropTypes.string,
  severity: PropTypes.string,
};

export default ErrorAlert;
