// ------------------------------------
// Imports
// ------------------------------------
import { getSteps, setSteps } from "../assets/common/common";
// ------------------------------------
// Constants
// ------------------------------------
export const ACTIVE_STEP_UPDATE = 'ACTIVE_STEP_UPDATE';
export const RESET_STEPPER = 'RESET_STEPPER';
// ------------------------------------
// Actions
// ------------------------------------
export const updateActiveStep = (payload) => ({
  type: ACTIVE_STEP_UPDATE,
  payload
});

export function clearStepper (payload) {
  return ({
    type: RESET_STEPPER,
    payload
  });
}
// ------------------------------------
// API calls
// ------------------------------------
export const setActiveStep = (step) => {
  return (dispatch) => {
    dispatch(updateActiveStep(step));
  };
};

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = {
  activeStep: getSteps(),
};

export const stepsReducer = (state = initialState, action) => {
  switch(action.type) {
  case ACTIVE_STEP_UPDATE:
    setSteps(action.payload);
    return { ...state, activeStep: action.payload };
  case RESET_STEPPER:
    return { ...state, activeStep: 0 };
  default: return state;
  }
};

export default stepsReducer;
