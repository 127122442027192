import { connect } from 'react-redux';
import ErrorAlert from './ErrorAlert';
import { clearError } from "../../store/errors";

function mapStateToProps (state) {
  return {
    errors: state.errors.errors,
    fix: JSON.stringify(state.errors.errors)
  };
}

const actions = {
  clearError
};

export default connect(mapStateToProps, actions)(ErrorAlert);
