// ------------------------------------
// Imports
// ------------------------------------
import { addError } from "./errors";
import apiClient from "../assets/common/interceptor";

// ------------------------------------
// Constants
// ------------------------------------
export const GENERATE_PDF = 'GENERATE_PDF';

// ------------------------------------
// Actions
// ------------------------------------

export function actionGeneratePdfRentalRequest (payload) {
  return ({
    type: GENERATE_PDF,
    payload
  });
}
// ------------------------------------
// API calls
// ------------------------------------

// RENTAL REQUEST
export const generatePdfRentalRequest = (data) => {
  return (dispatch) => {

    return apiClient.post('/organisation/order/pdf', data)
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem...
          return Promise.reject(data.data);
        }
      }).then(e => {
        dispatch(actionGeneratePdfRentalRequest(e));
        return Promise.resolve(e);
      })

      .catch(err => {
        localStorage.setItem('errorMessage', err.message);
        window.location.href = "/";
        if (typeof err === 'object' && typeof err.then === 'function') {
          err.then(e => {
            dispatch(addError(e.message));
          });
        }
      });
  };
};

// RENTAL REQUEST
export const generatePDF = (data) => {
  return (dispatch) => {
    return apiClient.post('/organisation/order/pdf', data)
      .then(data => {
        if (data.status < 400) {
          return Promise.resolve(data.data);
        } else {
          // If there was a problem...
          return Promise.reject(data.data);
        }
      }).then(e => {
        dispatch(actionGeneratePdfRentalRequest(e));
        return Promise.resolve(e);
      })
      .catch(err => {
        window.location.href = "/error";
        if (typeof err === 'object' && typeof err.then === 'function') {
          err.then(e => {
            dispatch(addError(e.message));
          });
        }
        return Promise.reject(err);
      });
  };
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  templateID: '',
  templateContent: {
    orderID: 1234,
    caregiver: {
      fullName: '',
      emailAddress: '',
      returnURL: '',
      phonenumber_for_questions: '',
    }
  },
  pdf: "",
};

export const pdfGenerationReducer = (state = initialState, action) => {
  switch (action.type) {
  case GENERATE_PDF:
    return { ...state, orderPdf: action.payload };
  default:
    return state;
  }
};

export default pdfGenerationReducer;
