import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import MaintenanceIcon from '../../assets/images/icons/reparatie.svg';

export const Maintenance = (props) => {
  const { textHead, textBody } = props;

  return (
    <div className="maintenance-page-container">
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '80%'
        }}
      >
        <div>
          <img
            src={ MaintenanceIcon } alt="Maintenance Icon"
          />
        </div>
        <div className="maintenance-text">
          <h1>{ textHead }</h1>
          <h2>{ textBody }</h2>
        </div>
      </Box>
    </div>
  );
};

Maintenance.propTypes = {
  textHead: PropTypes.string,
  textBody: PropTypes.string
};

export default Maintenance;
