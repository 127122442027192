import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import GA4React from 'ga-4-react';
import { GTMTrackingCode } from "./constants/api";

const ga4 = new GA4React(GTMTrackingCode);

const initializeGA4 = () => {
  return ga4.initialize().then(analytics => {
    return analytics;
  }).catch(error => {
    console.error('Error initializing GA4React:', error);
  });
};

initializeGA4().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
