import loadable from "@loadable/component";

const Navigation = loadable(() => import(/* webpackPrefetch: true */ "./Navigation/NavigationContainer"));
const ProductCards = loadable(() => import(/* webpackPrefetch: true */ "./ProductCards/ProductCards"));
const ProductConfigForm = loadable(() => import("./ProductConfigForm/ProductConfigForm"));
const ProductConfigSummary = loadable(() => import("./ProductConfigSummary/ProductConfigSummary"));
const Footer = loadable(() => import(/* webpackPrefetch: true */ "./Footer/Footer"));
const Location = loadable(() => import(/* webpackPrefetch: true */ "./Location/LocationContainer"));
const SingleDropDown = loadable(() => import(/* webpackPrefetch: true */ "./SingleDropdown/SingleDropDown"));
const ConfigSummary = loadable(() => import(/* webpackPrefetch: true */ "./ConfigSummary/ConfigSummary"));

// UI Components
const CustomStepper = loadable(() => import(/* webpackPrefetch: true */ "./CustomStepper/CustomStepper"));
const StandardStepper = loadable(() => import(/* webpackPrefetch: true */ "./CustomStepper/StandardStepper"));
const BasicModal = loadable(() => import(/* webpackPrefetch: true */ "./BasicModal/BasicModal"));
const CustomDialog = loadable(() => import(/* webpackPrefetch: true */ "./BasicModal/CustomDialog"));
const CustomStack = loadable(() => import(/* webpackPrefetch: true */ "./CustomStack/CustomStack"));
const ReverseStack = loadable(() => import(/* webpackPrefetch: true */ "./CustomStack/ReverseCustomStack"));
const MobileDrawer = loadable(() => import(/* webpackPrefetch: true */ "./MobileDrawer/MobileDrawer"));
const StickySignBar = loadable(() => import(/* webpackPrefetch: true */ "./StickySignBar/StickySignBar"));
const SearchBar = loadable(() => import(/* webpackPrefetch: true */ "./SearchBar/SearchBar"));
const SortBy = loadable(() => import(/* webpackPrefetch: true */ "./SortBy/SortBy"));
const NoInfoFound = loadable(() => import(/* webpackPrefetch: true */ "./NoInfoFound/NoInfoFound"));
const DragNDrop = loadable(() => import(/* webpackPrefetch: true */ "./DragNDrop/DragNDrop"));
const OrganisationField = loadable(() => import(/* webpackPrefetch: true */ "./OrganisationField/OrganisationField"));
const SuccessModal = loadable(() => import(/* webpackPrefetch: true */ "./SuccessModal/SuccessModal"));
const SearchRequestModal = loadable(
  () => import(/* webpackPrefetch: true */ "./SearchRequestModal/SearchRequestModal")
);

// Form UI
const CustomTextField = loadable(() => import(/* webpackPrefetch: true */ "./FormComponents/CustomTextField"));
const EditableTextField = loadable(() => import(/* webpackPrefetch: true */ "./FormComponents/EditableTextField"));
const CustomRadioGroup = loadable(() => import(/* webpackPrefetch: true */ "./FormComponents/CustomRadioGroup"));
const CustomCheckbox = loadable(() => import(/* webpackPrefetch: true */ "./FormComponents/CustomCheckbox"));

//Forms
const RentalClientForm = loadable(() => import(/* webpackPrefetch: true */ "./RentalClientForm/RentalClientForm"));
const DeliveryForm = loadable(() => import(/* webpackPrefetch: true */ "./DeliveryForm/DeliveryForm"));

// Orders
const OrderFilters = loadable(() => import(/* webpackPrefetch: true */ "./OrderFilters/OrderFilters"));
const OrderCards = loadable(() => import(/* webpackPrefetch: true */ "./OrderCards/OrderCards"));
const OrderButton = loadable(() => import(/* webpackPrefetch: true */ "./OrderCards/OrderButton"));
const DefaultCard = loadable(() => import(/* webpackPrefetch: true */ "./OrderCards/DefaultCard"));

// Buttons
const EditButton = loadable(() => import(/* webpackPrefetch: true */ "./EditButton/EditButton"));

// Loaders
const CardSkeleton = loadable(() => import(/* webpackPrefetch: true */ "./CardSkeleton/CardSkeleton"));
// Loaders
const CardPagination = loadable(() => import(/* webpackPrefetch: true */ "./CardPagination/CardPagination"));

export {
  Navigation,
  CustomStepper,
  StandardStepper,
  Location,
  ProductCards,
  DefaultCard,
  SingleDropDown,
  RentalClientForm,
  DeliveryForm,
  ProductConfigForm,
  ProductConfigSummary,
  ConfigSummary,
  Footer,
  CustomTextField,
  EditableTextField,
  CustomRadioGroup,
  BasicModal,
  CustomStack,
  ReverseStack,
  OrderFilters,
  OrderCards,
  OrderButton,
  MobileDrawer,
  StickySignBar,
  CustomCheckbox,
  EditButton,
  SearchBar,
  SortBy,
  CardSkeleton,
  NoInfoFound,
  DragNDrop,
  SearchRequestModal,
  OrganisationField,
  SuccessModal,
  CustomDialog,
  CardPagination
};
